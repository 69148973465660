import $ from 'jquery'

class Menu{

    init() {
        this.stick = $('.main-top')
        this.brand = $('.branding')
        this.logoLG = $('.logo-lg')
        this.logoSM = $('.logo-sm')
        this.overlay = $('<div class="overlay"></div>')
        this.menu = $('.mobileMenu')
        this.ul = $('<ul></ul>')
        this.setMobility()
        this.setEvents()

    }

    setEvents() {
        this.onScroll()
    }

    setMobility() {
        let that = this
        $("body").append(this.overlay);

        this.overlay.add(this.menu).on('scroll touchmove mousewheel', e => {
            e.preventDefault()
        })

        $('#mobileButton').click(e => {
            e.preventDefault()
            that.overlay.show().animate({opacity: 0.8},200)
            that.menu.toggleClass('slideOut')
        })

        this.overlay.click(e => {
            that.overlay.hide().animate({opacity:0}, 200)
            that.menu.toggleClass('slideOut')
        })

        this.menu.append(this.ul)

        $('.list-menu a').each( () => {
            let anchor = $(this)
            let li = $('<li></li>')
            anchor.clone().appendTo(li)
            this.ul.append(li)
        } )

    }

    onScroll(){
        let that = this
        $(window).scroll( function() {
            if ( $(this).scrollTop() > 100 ) {
                that.stick.addClass('stick')
                that.brand.addClass('stick')
                that.logoLG.hide()
                that.logoSM.show()
            } else {
                that.stick.removeClass('stick')
                that.brand.removeClass('stick')
                that.logoLG.show()
                that.logoSM.hide()
            }
        })
    }

    static initialize(){
        (new self()).init()
    }

}

export default Menu