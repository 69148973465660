import * as GMap from "./GMap/index"
import Menu from './src/Menu'

$(function(){

    let menu = new Menu();
    menu.init();

    if ( document.querySelector('.room-carousel') ) {
        $('.room-carousel').owlCarousel({
            loop: true,
            nav: false,
            center: true,
            dots: true,
            autoplay: false,
            autoplayTimeout: 3000,
            autoplayHoverPause: false,
            smartSpeed: 450,
            responsive: {
                375: {
                    items: 1
                },
                640: {
                    items: 1
                },
                768: {
                    items: 2
                },
                1024: {
                    items: 2
                }
            }
        });
    }

    if ( document.querySelector('.testimonial-carousel') ) {
        $('.testimonial-carousel').owlCarousel({
            items: 1,
            loop: true,
            nav: false,
            center: true,
            dots: true,
            autoplay: false,
            autoplayTimeout: 3000,
            autoplayHoverPause: false,
            smartSpeed: 450
        })
    }
    if (document.querySelector('.js-map')) {
        GMap.GMap('.js-map', 'AIzaSyAYpolYQPZ7_WgwbJFOdNVa-QkvJK45VK0')
    }
})

